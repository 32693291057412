import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
  {
    question: "What are game credits?",
    answer:
      "Game credits are a form of virtual currency used within specific games or gaming platforms. They can be used to purchase in-game items, unlock new levels, buy virtual goods, or even pay for subscription services within the game.      ",
  },
  {
    question: "How do game credits work?    ",
    answer:
      "Once purchased, game credits are added to your in-game or platform account. You can then spend these credits in the game or platform's store, similar to how you'd use real money in an online shop. The exact usage might vary depending on the game or platform.      ",
  },
  {
    question: "Is there a difference between game credits purchased from AmpCreds and those bought in-app?    ",
    answer:
      "No, the game credits themselves are identical. The main difference lies in more payment method option,  potential discounts, and other added benefits you get when buying from AmpCreds.",
  },
  {
    question: "Why are game credits sometimes cheaper on AmpCreds compared to in-app purchases?    ",
    answer:
      "We work directly with game publishers and bulk-purchase game credits, allowing us to negotiate better prices. We pass on these savings to our customers, ensuring you get the best value for your money.",
  },
  {
    question: "How do I make a purchase on Amp Creds?    ",
    answer:
      "Simply browse our catalog, select the digital goods you wish to purchase, add them to your cart, and proceed to checkout. We offer a variety of payment methods for your convenience.      ",
  },
  {
    question: "Is my payment information safe with AmpCreds?    ",
    answer:
      "Absolutely. We prioritize the security of our users and use advanced encryption methods to ensure your payment information is kept confidential and secure.      ",
  },
  {
    question: "How do I receive my purchased digital goods?    ",
    answer:
      "Once your purchase is complete, you'll receive your digital goods instantly via email or directly within your AmpCreds account, depending on the product.",
  },
  {
    question: "What if the game key or digital item I purchased doesn't work?    ",
    answer:
      "We ensure the legitimacy of all our products. If you encounter any issues, please reach out to our customer support, and we'll assist you promptly.      ",
  },
  {
    question: "How do I contact AmpCreds support?    ",
    answer:
      "You can reach out to our support team via the 'Contact Us' page on our website or by emailing support@ampcreds.com. We're here to help!      ",
  },
  {
    question: "Can I return or exchange a product?    ",
    answer:
      "Due to the nature of digital goods, all sales are final. However, if there's an issue with your purchase, please contact our support team, and we'll do our best to resolve it.",
  },
  {
    question: "Do you offer any discounts or promotions?    ",
    answer:
      "Yes, we frequently have promotions and special offers. Be sure to sign up for our newsletter or follow us on social media to stay updated on the latest deals.      ",
  },
  // More questions...
]

export default function Example() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
