import { MakeProtectedApiCall } from "../../../Services/api";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getHeadersWithoutAuth = () => {
  return {
    "Content-Type": "application/json",
  };
};

export const GET_VOUCHERS_BRANDS = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_VOUCHER_BRANDS_LOADING", data: true });
      const url = `${baseUrl}/mall/vouchers/brand`;
      const res = await MakeProtectedApiCall(
        url,
        "GET",
        getHeadersWithoutAuth()
      );
      dispatch({ type: "GET_VOUCHERS_BRANDS", data: res?.data });
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    } finally {
      dispatch({ type: "IS_VOUCHER_BRANDS_LOADING", data: false });
    }
  };
};

export const GET_VOUCHERS_BRAND_BY_ID = (ID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_VOUCHER_BRAND_LOADING", data: true });
      const url = `${baseUrl}/mall/vouchers/brand/${ID}`;
      const res = await MakeProtectedApiCall(
        url,
        "GET",
        getHeadersWithoutAuth()
      );
      dispatch({ type: "GET_VOUCHERS_BRAND_BY_ID", data: res?.data });
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    } finally {
      dispatch({ type: "IS_VOUCHER_BRAND_LOADING", data: false });
    }
  };
};

export const GET_VOUCHERS_BRANDS_BY_SEARCH = (value) => {
  return async (dispatch) => {
    try {
      const url = `${baseUrl}/mall/vouchers/brand/search?name=${value}`;

      const res = await MakeProtectedApiCall(
        url,
        "GET",
        getHeadersWithoutAuth()
      );
      dispatch({ type: "GET_VOUCHERS_BRANDS_BY_SEARCH", data: res?.data });
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
};
