export default function ScreenLoaderComponent() {
  return (
    <div className="h-full border-2 p-3 rounded-md mx-auto mt-20">
      <div className="animate-pulse p-2 space-x-5 space-y-3">
        <div className="w-12 bg-gray-300 h-12 m-2 rounded-full"></div>
        <div className="flex flex-col space-y-3">
          <div className="w-full bg-gray-300 h-6 rounded-md"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md"></div>
          <div className="w-full bg-gray-300 h-6 rounded-md"></div>
        </div>
      </div>
    </div>
  );
}
