import HeaderComponent from "src/Components/Header";
import FooterComponent from "src/Components/Footer";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// const pro_winners = [
//   { 
//     name: 'Perkz', 
//     email: '-', 
//     prize: '1446 Diamonds (worth PHP 1272)' 
//   },{ 
//     name: 'Kirk', 
//     email: '-', 
//     prize: '1446 Diamonds (worth PHP 1272)' 
//   },{ 
//     name: 'Kyle', 
//     email: '-', 
//     prize: '1446 Diamonds (worth PHP 1272)' 
//   }
// ]

const random_winners = [
  { 
    name: 'Jerico Panaligan', 
    email: 'pan*****@gmail.com', 
    prize: '1446 Diamonds (worth PHP 1272)' 
  },{ 
    name: 'Robel Torio', 
    email: 'rob*****@gmail.com', 
    prize: '1446 Diamonds (worth PHP 1272)' 
  },{ 
    name: 'Ivan Kyle manabat', 
    email: 'iva*****@gmail.com', 
    prize: '1446 Diamonds (worth PHP 1272)' 
  }
]

const MinanaPage = () => {
  const user = useSelector((state) => state.authReducer?.user);
  const navigate = useNavigate();

  return (
    <>
      <div className="min-h-full">
        <HeaderComponent />
        <main>
        <div className="bg-blue-500 text-center text-white p-1">
          <span className="text-sm">
            Disclaimer: winners will be reached out by Minana team and game credits will be sent directly via Amp Creds to their game account
          </span>
        </div>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">

          {user ? (
            <>
            {/* <div className="px-4 sm:px-6 lg:px-8">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Category (Pro):
                  </h1>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Email
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Prize
                            </th>
                            
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {pro_winners.map((winner) => (
                            <tr key={winner.name}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {winner.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{winner.email}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{winner.prize}</td>
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="px-4 sm:px-6 lg:px-8 py-6">
              <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                  <h1 className="text-base font-semibold leading-6 text-gray-900">
                    Category (Random):
                  </h1>
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                              Name
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Email
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Prize
                            </th>
                            
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {random_winners.map((winner) => (
                            <tr key={winner.name}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {winner.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{winner.email}</td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{winner.prize}</td>
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>

            ) : (
                <>

            <div className="overflow-hidden bg-white flex flex-col gap-20 w-full items-center pt-8 pb-20 p-4">
                <div className="self-stretch flex flex-col gap-16 items-center">
                <div className="self-stretch flex flex-col gap-10 h-32 shrink-0 items-center">
                    {/* <div
                        id="MINANATRYOUTS1"
                        className="text-4xl font-sans font-bold tracking-[-0.6] leading-[40px]"
                    > */}
                        {/* MINANA | <span className="font-semibold">TRYOUTS</span> */}
                        <div className="content-center md:w-1/3 w-2/3">
                          <img alt="banner" src="/minana/tryouts.png" className="h-full w-full object-cover object-center sm:rounded-lg"/>
                        </div>  
                    {/* </div> */}
                    {/* <div className="content-center w-10/12">
                  <img alt="banner" src="/minana/tryouts.webp" className="h-full w-full object-cover object-center sm:rounded-lg"/>
                </div> */}
                    <h1 className="text-xl text-gray-600 text-center">
                      Join the Minana Evos tryouts and claim your destiny! Here’s your chance to prove your dedication and write your own esports story at the MPL Philippines. #ExcellenceInherited
                    </h1>
                    {/* <div className="text-center text-2xl font-sans font-medium tracking-[-0.6] leading-[40px] self-start w-full">
                      Join the Minana Evos tryouts and claim your destiny! Here’s your chance to prove your dedication and write your own esports story at the MPL Philippines. #ExcellenceInherited
                    </div> */}
                </div>
                <div className="content-center w-10/12">
                  <img alt="banner" src="/minana/team-photo.webp" className="h-full w-full object-cover object-center sm:rounded-lg"/>
                </div>
                
                </div>
                    <button
                        id="Button1"
                        onClick={() => navigate("/login")}
                        className="overflow-hidden bg-[#4f46e5] flex flex-row justify-center gap-2 h-16 shrink-0 items-center px-6 py-3 rounded-lg"
                    >
                        <div
                        id="Text1"
                        className="text-2xl font-sans font-bold leading-[36px] text-white"
                        >
                        View winners
                        </div>
                    </button>
            </div>
        
            <div className="overflow-hidden bg-white flex flex-row justify-center pb-16 gap-16 w-full items-end">
                <div className="text-center text-2xl font-sans font-bold tracking-[0.24] leading-[28.8px] text-[#111827]">
                Powered by
                </div>
            </div>

            <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-6 xl:gap-x-8 pb-16 p-4">
                <img alt="logo1" className="w-[150px] h-20 relative" src="/minana/logos/Frame2.png" />
                <img alt="logo2" className="w-[150px] h-20 relative" src="/minana/logos/Frame3.png" />
                <img alt="logo3" className="w-[200px] h-20 relative" src="/minana/logos/Frame4.png" />
                <img alt="logo4" className="w-[150px] h-20 relative" src="/minana/logos/Frame5.png" />
                <img alt="logo5" className="w-[150px] h-20 relative" src="/minana/logos/Frame6.png" />
                <img alt="logo6" className="w-[150px] h-20 relative" src="/minana/logos/Frame7.png" />
            </div>
            </>
            )}
          </div>
        </main>
        <FooterComponent />
      </div>
      
    </>
  );
};

export default MinanaPage;
