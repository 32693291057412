import { CheckCircleIcon } from "@heroicons/react/20/solid";

export default function FailedAlertComponent() {
  return (
    <div className={`rounded-md bg-rose-50 p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-rose-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-rose-800">Payment Failed</h3>
          <div className="mt-2 text-sm text-rose-700">
            <p>
              We were unable to process your payment. Please try again.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
