import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ProtectedRoute from "./protectedRoutes";
import ScrollToTop from "./scrollToTop";
import {
  HomePage,
  VoucherBrandPage,
  LoginPage,
  FAQsPage,
  AboutUs,
  BookingPage,
  CheckoutPage,
  BookingHistoryPage,
  ProfilePage,
  BookingInfoPage,
  MinanaPage,
} from "../View";

const AllRoutes = () => (
  <Router>
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/brands/:ID" element={<VoucherBrandPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/faqs" element={<FAQsPage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/minana" element={<MinanaPage />} />
        <Route element={<ProtectedRoute />}>
          <Route
            path="/brands/:ID/voucher/:voucherUID"
            element={<BookingPage />}
          />
          <Route path="/booking/checkout" element={<CheckoutPage />} />
          <Route path="/booking/history" element={<BookingHistoryPage />} />
          <Route path="/booking/:ID" element={<BookingInfoPage />} />
          <Route path="/profile" element={<ProfilePage />} />
        </Route>
      </Routes>
    </ScrollToTop>
  </Router>
);

export default AllRoutes;
