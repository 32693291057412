// ** Redux Imports
import {combineReducers} from 'redux';
import voucherReducer from './vouchers';
import voucherBrandReducer from './voucherBrands';
import authReducer from './auth';
import bookingReducer from './bookings';

const rootReducer = combineReducers({
	voucherReducer,
	voucherBrandReducer,
	authReducer,
	bookingReducer,
})

export default rootReducer
