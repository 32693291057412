// **  Initial State
const initialState = {
  voucherBrands: [],
  voucherBrandsBySearch: [],
  voucherBrandInfo: null,
  isVoucherBrandsLoading: false,
  isBrandLoading: false,
};

const voucherBrandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_VOUCHERS_BRANDS":
      return {
        ...state,
        voucherBrands: action.data,
      };
    case "GET_VOUCHERS_BRAND_BY_ID":
      return {
        ...state,
        voucherBrandInfo: action.data,
      };
    case "GET_VOUCHERS_BRANDS_BY_SEARCH":
      return {
        ...state,
        voucherBrandsBySearch: action.data,
      };
    case "IS_VOUCHER_BRANDS_LOADING":
      return {
        ...state,
        isVoucherBrandsLoading: action.data,
      };
      case "IS_VOUCHER_BRAND_LOADING":
        return {
          ...state,
          isBrandLoading: action.data,
        }
    default:
      return state;
  }
};

export default voucherBrandReducer;
