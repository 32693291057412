import { MakeProtectedApiCall } from "../../../Services/api";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getHeadersWithoutAuth = () => {
  return {
    "Content-Type": "application/json",
  };
};

export const GET_VOUCHERS_BY_ID = (voucherId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_VOUHCER_LOADING", data: true });
      const url = `${baseUrl}/mall/vouchers/${voucherId}`;
      const res = await MakeProtectedApiCall(
        url,
        "GET",
        getHeadersWithoutAuth()
      );
      dispatch({ type: "GET_VOUCHERS_BY_ID", data: res?.data });
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    } finally {
      dispatch({ type: "IS_VOUHCER_LOADING", data: false });
    }
  };
};
