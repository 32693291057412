// **  Initial State
const initialState = {
  user: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USER_PROFILE":
      return {
        ...state,
        user: action.data,
      };
    case "HANDLE_USER_LOGOUT":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
