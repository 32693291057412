// **  Initial State
const initialState = {
  userLatestBooking: null,
  userBookings: null,
  bookingInfo: null,
  isBookingHistoyLoading: false,
  isBookingInfoLoading: false,
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LATEST_BOOKING":
      return {
        ...state,
        userLatestBooking: action?.data,
      };
    case "USER_BOOKING_HISTORY":
      return {
        ...state,
        userBookings: action?.data,
      };
    case "GET_BOOKING_BY_ID":
      return {
        ...state,
        bookingInfo: action?.data,
      };
    case "IS_BOOKING_HISTORY_LOADING": 
      return {
        ...state,
        isBookingHistoyLoading: action.data,
      };
    case "IS_BOOKING_INFO_LOADING": 
      return {
        ...state,
        isBookingInfoLoading: action.data,
      }
    default:
      return state;
  }
};

export default bookingReducer;
