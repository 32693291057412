/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GET_USER_PROFILE, HANDLE_USER_LOGOUT } from "src/Redux/actions/auth";
import { GET_VOUCHERS_BRANDS_BY_SEARCH } from "src/Redux/actions/voucherBrands";
import { Fragment } from "react";
const ENV = process.env.REACT_APP_ENV;

const navigation = [
  { name: "About", href: "/about-us" },
  { name: "FAQs", href: "/faqs" },
  { name: "Help", href: "https://forms.gle/XSVoW1QchktEJjnF7", external: true },
  { name: "Minana", href: "/minana" },
];

const userNavigation = [
  { id: "profile", name: "Your Profile", href: "/profile" },
  { id: "bookings", name: "Bookings History", href: "/booking/history" },
  { id: "log-out", name: "Sign out" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const user = useSelector((state) => state.authReducer?.user);
  const voucherBrandsBySearch = useSelector(
    (state) => state.voucherBrandReducer?.voucherBrandsBySearch
  );

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(HANDLE_USER_LOGOUT());
    navigate("/");
  };

  const handleNavigate = (id) => {
    navigate(`/brands/${id}`);
    setSearchText("")
    dispatch(GET_VOUCHERS_BRANDS_BY_SEARCH(null));
  };

  const handleSearch = (e) => {
    if (e.target.value.length > 0) {
      dispatch(GET_VOUCHERS_BRANDS_BY_SEARCH(e.target.value));
      setSearchText(e.target.value);
    } else {
      setSearchText(e.target.value);
      dispatch(GET_VOUCHERS_BRANDS_BY_SEARCH(null));
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(GET_USER_PROFILE());
    }
  }, [dispatch]);

  return (
    <header className="bg-white">
    {ENV === "production" ? null : (
        <div className="bg-red-500 text-center text-white p-1">
          <span className="text-sm">This is a demo website. Please do not use your real credit card details.</span>
        </div>
      )
    }

    {ENV === "production" ? (
        <div className="bg-blue-500 text-center text-white p-1">
          <span className="text-sm">12.12 Sale celebration with Team Minana | 5% Off Site-wide | Best viewed on Desktop/laptop</span>
        </div>
      ) : null
    }
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between gap-x-6 p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">AmpCreds</span>
            <img
              className="h-8 w-auto"
              src="/amp-creds-logo.png"
              alt="AmpCreds"
            />
          </a>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-sm font-semibold leading-6 text-gray-900"
              target={item?.external ? "_blank" : "_self"}
              rel="noreferrer"
            >
              {item.name}
            </a>
          ))}
        </div>
        {/* <div>
          <input
            type="search"
            x-model="search"
            placeholder="Search for a voucher"
            onChange={handleSearch}
            className="py-3 px-4 rounded-full shadow focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 border border-gray-100"
          />
          {voucherBrandsBySearch?.length ? (
            <Menu as="div" className="relative ml-3">
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items>
                  {voucherBrandsBySearch?.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <div>
                          <a
                            onClick={handleLogout}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                          >
                            {item.name}
                          </a>
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          ) : null}
        </div> */}
        <div className="flex flex-1 items-center justify-end">
          <div className="hidden lg:block flex-col items-center justify-center relative">
            <input
              type="search"
              placeholder="Search"
              value={searchText}
              onChange={handleSearch}
              className="py-3 px-4 rounded-full shadow focus:outline-none focus:shadow-lg focus:shadow-slate-200 duration-100 border border-gray-100"
            />
            {voucherBrandsBySearch?.length ? (
              <Transition
                show={true}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <ul className="absolute right-0 z-10 mt-2 w-full origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {voucherBrandsBySearch?.map((brand) => (
                    <li
                      className="w-full text-gray-700 p-2 hover:bg-slate-100 cursor-pointer" 
                      onClick={() => handleNavigate(brand?.uid)}
                      key={brand.uid}
                    >
                      {brand.name}
                    </li>
                  ))}
                </ul>
              </Transition>
            ) : null}
          </div>

          {/* {voucherBrandsBySearch?.length ? (
            
          ) : null} */}
          {user ? (
            <div>
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full"
                      src={user?.avatar}
                      alt="userAvatar"
                      referrerPolicy="no-referrer"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <div>
                            {item?.id === "log-out" ? (
                              <a
                                onClick={handleLogout}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Log Out
                              </a>
                            ) : (
                              <a
                                href={item.href}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </a>
                            )}
                          </div>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          ) : (
            <button
              onClick={() => navigate("/login")}
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Log in
            </button>
          )}
        </div>

        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center gap-x-6">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">AmpCreds</span>
              <img
                className="h-8 w-auto"
                src="/amp-creds-logo.png"
                alt="AmpCreds"
              />
            </a>
            {/* <a
              href="#"
              className="ml-auto rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Sign up
            </a> */}
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              {/* <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
              </div> */}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
