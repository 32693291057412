import HeaderComponent from "src/Components/Header";
import FooterComponent from "src/Components/Footer";
import ProfileLayout from "src/Layouts/Profile";
import ScreenLoaderComponent from "src/Components/ScreenLoader";
import { useSelector } from "react-redux";

const ProfilePage = () => {
  const isLoading = useSelector(
    state => state?.appStatusReducer?.isLoading
  )
  return (
    <>
      <div className="min-h-full">
        <HeaderComponent />
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          {isLoading ? <ScreenLoaderComponent /> : <ProfileLayout />}
          </div>
        </main>
        <FooterComponent />
      </div>
    </>
  );
};

export default ProfilePage;
