import { MakeProtectedApiCall } from "../../../Services/api";

const baseUrl = process.env.REACT_APP_BASE_URL;

const getHeadersWithAuth = () => {
  return {
    authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
    "content-type": "application/json",
  };
};

export const BOOK_VOUCHER = (voucherId, data) => {
  return async () => {
    try {
      const url = `${baseUrl}/mall/vouchers/booking/${voucherId}`;
      const res = await MakeProtectedApiCall(
        url,
        "POST",
        getHeadersWithAuth(),
        data
      );
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      } else {
        return { success: false };
      }
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
};

export const GET_BOOKING_BY_ID = (bookingId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_BOOKING_INFO_LOADING", data: true });
      const url = `${baseUrl}/mall/vouchers/booking/${bookingId}`;
      const res = await MakeProtectedApiCall(url, "GET", getHeadersWithAuth());
      if (res?.status === 200) {
        dispatch({ type: "GET_BOOKING_BY_ID", data: res?.data });
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    } finally {
      dispatch({ type: "IS_BOOKING_INFO_LOADING", data: false });
    }
  };
};

export const USER_LATEST_BOOKING = () => {
  return async (dispatch) => {
    try {
      const url = `${baseUrl}/users/bookings/latest`;
      const res = await MakeProtectedApiCall(url, "GET", getHeadersWithAuth());
      if (res?.status === 200) {
        dispatch({ type: "USER_LATEST_BOOKING", data: res?.data });
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    }
  };
};

export const USER_BOOKING_HISTORY = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_BOOKING_HISTORY_LOADING", data: true });
      const url = `${baseUrl}/users/bookings`;
      const res = await MakeProtectedApiCall(url, "GET", getHeadersWithAuth());
      if (res?.status === 200) {
        dispatch({ type: "USER_BOOKING_HISTORY", data: res?.data });
        return { success: true, data: res?.data };
      }
      return { success: false };
    } catch (error) {
      console.log(error.response.data.replace(/\\/g, ""));
    } finally {
      dispatch({ type: "IS_BOOKING_HISTORY_LOADING", data: false });
    }
  };
};
