import { useNavigate } from "react-router-dom";

const VoucherBrandCard = ({ voucherBrand }) => {
  const navigate = useNavigate()

  return (
    <div onClick={() => navigate(`/brands/${voucherBrand.uid}`)}>
      <div
        key={voucherBrand.uid}
        className="p-2 shadow bg-white rounded-lg hover:shadow-xl hover:-translate-y-1 transition ease-in-out delay-150"
      >
        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
          <img
            src={voucherBrand.logo}
            alt=""
            className="pointer-events-none object-cover group-hover:opacity-75"
          />
          <button type="button" className="absolute inset-0 focus:outline-none">
            <span className="sr-only">
              View details for {voucherBrand.name}
            </span>
          </button>
        </div>
        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-center text-gray-900">
          {voucherBrand.name}
        </p>
      </div>
    </div>
  );
};

export default VoucherBrandCard;
