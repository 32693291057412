import { useNavigate } from "react-router-dom";

const VoucherBrandCard = ({ voucherBrand }) => {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(`/brands/${voucherBrand.uid}`)}>
      <div
        key={voucherBrand.uid}
        className="p-2 shadow bg-white rounded-lg hover:shadow-xl hover:-translate-y-1 transition ease-in-out delay-150"
      >
        <div className="aspect-h-7 aspect-w-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
          <img
            src={voucherBrand.logo}
            alt="voucherImage"
            className="pointer-events-none object-cover group-hover:opacity-75"
          />

          <button type="button" className="inset-0 focus:outline-none">
            <span className="sr-only">
              View details for {voucherBrand.name}
            </span>
          </button>
          <div className="text-center right-0 rounded-r-md top-3 h-7 w-20 bg-white">
            5% off
          </div>
        </div>
        <p className="pointer-events-none mt-2 block truncate text-sm text-center font-medium text-gray-900">
          {voucherBrand.name}
        </p>
        {/* <p className="pointer-events-none block text-sm font-medium text-gray-500">
            {voucherBrand.description}
          </p> */}
      </div>
    </div>
  );
};

export default VoucherBrandCard;
