import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_BOOKING_BY_ID } from "src/Redux/actions/bookings";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";
import ScreenLoaderComponent from "src/Components/ScreenLoader";
import { CheckBadgeIcon, XCircleIcon } from "@heroicons/react/24/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BookingInfoLayout({ paramId }) {
  const navigate = useNavigate();
  const bookingInfo = useSelector(
    (state) => state?.bookingReducer?.bookingInfo
  );
  const isBookingLoading = useSelector(
    (state) => state?.bookingReducer?.isBookingInfoLoading
  );
  const { ID } = useParams();
  let bookingId;
  if (paramId) {
    bookingId = paramId;
  } else {
    bookingId = ID;
  }
  const dispatch = useDispatch();

  const steps = {
    PENDING: 1,
    PAYMENT_COMPLETE: 3,
    COMPLETE: 3,
    PAYMENT_FAILED: 1,
    FAILED: 3,
  };

  const progressBarColors = {
    PENDING: "bg-yellow-600",
    PAYMENT_COMPLETE: "bg-yellow-600",
    COMPLETE: "bg-green-600",
    PAYMENT_FAILED: "bg-rose-600",
    FAILED: "bg-rose-600",
  };

  const status = {
    PENDING: "Awaiting payment confirmation",
    PAYMENT_COMPLETE: "Payment Completed. Waiting for Voucher confirmation",
    COMPLETE: "Voucher purchase successful",
    PAYMENT_FAILED: "Payment failed",
    FAILED: "Voucher purchase failed",
  };

  const handleViewProduct = (router) => {
    navigate(router);
  };

  useEffect(() => {
    dispatch(GET_BOOKING_BY_ID(bookingId));
  }, [bookingId, dispatch]);

  return !isBookingLoading ? (
    <div className="bg-gray-50">
      {/* <div>
        {bookingInfo?.status === "COMPLETE" && <SuccessAlertComponent />}
        {bookingInfo?.status === "PENDING" && <PendingAlertComponent />}
        {bookingInfo?.status === "FAILED" && <FailedAlertComponent />}
      </div> */}
      <div className="mx-auto max-w-2xl pt-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="space-y-2 px-4 sm:flex sm:items-baseline sm:justify-between sm:space-y-0 sm:px-0">
          <div className="flex sm:items-baseline sm:space-x-4">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Order <span className="text-base">#{bookingInfo?.uid}</span>
            </h1>
          </div>
          <p className="text-sm text-gray-600">
            Order placed{" "}
            <time dateTime="2021-03-22" className="font-medium text-gray-900">
              {moment(bookingInfo?.created_at).format("MMM D, YYYY")}
            </time>
          </p>
        </div>

        <div className="mt-6">
          <h2 className="sr-only">Product purchased</h2>

          <div className="space-y-8">
            <div className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border">
              <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                <div className="sm:flex lg:col-span-7">
                  <div className="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                    <img
                      src={bookingInfo?.voucher?.brand?.logo}
                      alt={bookingInfo?.voucher?.brand?.logo}
                      className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                    />
                  </div>

                  <div className="mt-6 sm:ml-6 sm:mt-0">
                    <p className="mt-2 text-sm font-medium text-gray-900">
                      {bookingInfo?.voucher?.currency}{" "}
                      {bookingInfo?.amount}
                    </p>
                    <p className="mt-3 text-sm text-gray-500">
                      {bookingInfo?.voucher?.description}
                      <div>
                        <button
                          className="text-indigo-600"
                          onClick={() =>
                            handleViewProduct(
                              `/brands/${bookingInfo?.voucher?.brand?.uid}`
                            )
                          }
                        >
                          View
                          <span className="hidden lg:inline"> Product</span>
                        </button>
                      </div>
                    </p>
                  </div>
                </div>

                <div className="mt-6 lg:col-span-5 lg:mt-0">
                  <div className="grid grid-cols-2 gap-x-6 text-sm">
                    <div>
                      <dt className="font-medium text-gray-900">
                        {bookingInfo?.booking_type === "GAMES-TOP-UP"
                          ? "Games Details"
                          : "Phone Number"}
                      </dt>
                      <dd className="mt-3 text-gray-500">
                        <span className="block">
                          <p>{bookingInfo?.account_number?.clientid}</p>
                          <p>
                            {bookingInfo?.account_number?.clientserverid &&
                              bookingInfo?.account_number?.clientserverid}
                          </p>
                          <p>
                            {bookingInfo?.account_number?.clientsubid &&
                              bookingInfo?.account_number?.clientsubid}
                          </p>
                          <p>
                            {bookingInfo?.account_number?.clientusername &&
                              bookingInfo?.account_number?.clientusername}
                          </p>
                        </span>
                      </dd>
                    </div>
                    <div>
                      <dt className="font-medium text-gray-900">
                        Payment Details
                      </dt>
                      <dd className="mt-3 space-y-3 text-gray-500">
                        <p>{bookingInfo?.transaction?.payment_method}</p>
                      </dd>
                    </div>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
                <h4 className="sr-only">Status</h4>
                <div className="mt-6" aria-hidden="true">
                  <div className="overflow-hidden rounded-full bg-gray-200">
                    <div
                      className={classNames(
                        "h-2 rounded-full",
                        progressBarColors[bookingInfo?.status]
                      )}
                      style={{
                        width: `calc((${
                          steps?.[bookingInfo?.status]
                        } * 2 + 1) / 6 * 100%)`,
                      }}
                    />
                  </div>
                  <div className="mt-6 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
                    <div className="text-indigo-600 flex">
                      Order placed{" "}
                      <span className="p-1">
                        <CheckBadgeIcon color="green" height={15} width={15} />
                        {/* <XCircleIcon color="red" height={15} width={15} /> */}
                      </span>{" "}
                    </div>
                    <div
                      className={classNames(
                        !["PAYMENT_FAILED", "PENDING"].includes(
                          bookingInfo?.status
                        )
                          ? "text-indigo-600"
                          : "",
                        "flex justify-center"
                      )}
                    >
                      Payment{" "}
                      <span className="p-1">
                        {["PAYMENT_FAILED", "PENDING"].includes(
                          bookingInfo?.status
                        ) ? (
                          <XCircleIcon color="red" height={15} width={15} />
                        ) : (
                          <CheckBadgeIcon
                            color="green"
                            height={15}
                            width={15}
                          />
                        )}
                      </span>{" "}
                    </div>
                    <div
                      className={classNames(
                        ["COMPLETE", "FAILED", "PAYMENT_COMPLETE"].includes(
                          bookingInfo?.status
                        )
                          ? "text-indigo-600"
                          : "",
                        "flex justify-end"
                      )}
                    >
                      Delivery
                      <span className="p-1">
                        {["FAILED", "PAYMENT_COMPLETE"].includes(
                          bookingInfo?.status
                        ) ? (
                          <XCircleIcon color="red" height={15} width={15} />
                        ) : ["COMPLETE", "FAILED", "PAYMENT_COMPLETE"].includes(
                            bookingInfo?.status
                          ) ? (
                          <CheckBadgeIcon
                            color="green"
                            height={15}
                            width={15}
                          />
                        ) : null}
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>

              <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:p-8">
                <div className="font-medium text-gray-900">
                  Status:
                  <p className="mt-3 space-y-3 text-sm text-gray-500">
                    {status[bookingInfo?.status]}
                  </p>
                </div>
                <div className="font-medium text-gray-900">
                  Transaction Id:
                  <p className="mt-3 space-y-3 text-sm text-gray-500">
                    {bookingInfo?.transaction_uid}
                  </p>
                </div>
                <div className="font-medium text-gray-900">
                  Amount:
                  <p className="mt-3 space-y-3 text-sm text-gray-500">
                    {bookingInfo?.amount}
                  </p>
                </div>
                <div className="font-medium text-gray-900">
                  Payment Method:
                  <p className="mt-3 space-y-3 text-sm text-gray-500">
                    {bookingInfo?.transaction?.payment_method}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <ScreenLoaderComponent />
  );
}
