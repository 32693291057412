import HeaderComponent from "src/Components/Header";
import FooterComponent from "src/Components/Footer";
import HomeLayout from "src/Layouts/Home";
import FAQsLayout from "src/Layouts/FAQs";

const HomePage = () => {
  return (
    <>
      <div className="min-h-full">
        <HeaderComponent />
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <>
              <HomeLayout />
              <FAQsLayout />
            </>
          </div>
        </main>
        <FooterComponent />
      </div>
    </>
  );
};

export default HomePage;
