import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_VOUCHERS_BRANDS,
  GET_VOUCHERS_BRAND_BY_ID,
} from "src/Redux/actions/voucherBrands";
import VoucherBrandCard from "./Components/card";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import ScreenLoaderComponent from "../ScreenLoader";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function VoucherBrandComponent({ ID }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useSelector((state) => state.voucherBrandReducer);
  const voucherBrands = store?.voucherBrands;
  const brandDetails = store?.voucherBrandInfo;
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const isVoucherBrandsLoading = store?.isVoucherBrandsLoading;
  const isVoucherBrandLoading = store?.isBrandLoading;
  useEffect(() => {
    dispatch(GET_VOUCHERS_BRAND_BY_ID(ID));
    dispatch(GET_VOUCHERS_BRANDS());
  }, [dispatch, ID]);

  return !isVoucherBrandsLoading || !isVoucherBrandLoading ? (
    <div className="bg-white">
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <div className="flex flex-col-reverse">
            <div className="aspect-h-1 aspect-w-1 w-full">
              <div>
                <img
                  src={brandDetails?.logo}
                  alt="brandCover"
                  className="h-full w-full object-cover object-center sm:rounded-lg"
                />
              </div>
            </div>
          </div>

          {/* Voucher Brand info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-3xl font-bold text-gray-900">
              {brandDetails?.name}
            </h1>
            <div className="grid gap-4 grid-cols-2 mt-5">
              {brandDetails?.vouchers?.map((voucher) => (
                <button
                  key={voucher.uid}
                  className={classNames(
                    selectedVoucher?.uid === voucher.uid
                      ? "border-indigo-600"
                      : null,
                    "border rounded-md bg-white p-1 flex flex-col justify-center items-center hover:bg-slate-200"
                  )}
                  onClick={() => setSelectedVoucher(voucher)}
                >
                  <h1 className="text-primary font-bold text-base ">
                    {voucher.description}
                  </h1>
                  <p className="text-gray-500">
                    {voucher.currency} <span>{voucher.discounted_price}</span>{" "}
                    <span className="line-through">{voucher.actual_price}</span>
                  </p>
                </button>
              ))}
            </div>
            <div className="mt-10 flex">
              {selectedVoucher ? (
                <button
                  onClick={() =>
                    navigate(`/brands/${ID}/voucher/${selectedVoucher.uid}`)
                  }
                  className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                >
                  Buy Now
                </button>
              ) : (
                <button
                  disabled
                  className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-gray-300 px-8 py-3 text-base font-medium text-whitesm:w-full"
                >
                  Buy Now
                </button>
              )}
              {/* <button
                  disabled
                  className="flex max-w-xs flex-1 items-center justify-center rounded-md border border-transparent bg-gray-300 px-8 py-3 text-base font-medium text-whitesm:w-full"
                >
                Coming soon
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 flex justify-between">
        <h3 className="text-xl font-bold">More Products On AmpCred</h3>
        <Link
          className="flex text-sm text-gray-400 font-bold hover:underline hover:cursor-pointer"
          to="/"
        >
          View More <ChevronRightIcon className="h-5 w-5" />{" "}
        </Link>
      </div>
      <div className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 px-4 lg:grid-cols-4 xl:gap-x-8">
        {voucherBrands.slice(0, 8).map((voucherBrand) => (
          <VoucherBrandCard
            key={voucherBrand.uid}
            voucherBrand={voucherBrand}
          />
        ))}
      </div>
    </div>
  ) : (
    <ScreenLoaderComponent />
  );
}
