import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScreenLoaderComponent from "src/Components/ScreenLoader";
import { USER_BOOKING_HISTORY } from "src/Redux/actions/bookings";

export default function BookingHistoryLayout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userBookings = useSelector(
    (state) => state?.bookingReducer?.userBookings
  );
  const isBookingHistoyLoading = useSelector(
    (state) => state?.bookingReducer?.isBookingHistoyLoading
  );

  const handleViewProduct = (router) => {
    navigate(router);
  };

  useEffect(() => {
    dispatch(USER_BOOKING_HISTORY());
  }, [dispatch]);

  return !isBookingHistoyLoading ? (
    <div className="bg-white">
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:pb-24">
        <div className="max-w-xl">
          <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Order history
          </h1>
          <p className="mt-2 text-sm text-gray-500">
            Check the status of recent orders.
          </p>
        </div>

        <div className="mt-16">
          <h2 className="sr-only">Recent orders</h2>

          <div className="space-y-20">
            <div>
              <table className="mt-4 w-full text-gray-500 sm:mt-6">
                <caption className="sr-only">Products</caption>
                <thead className="sr-only text-left text-sm text-gray-500 sm:not-sr-only">
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pr-8 font-normal sm:w-2/5 lg:w-1/3"
                    >
                      Product
                    </th>
                    <th
                      scope="col"
                      className="hidden w-1/5 py-3 pr-8 font-normal sm:table-cell"
                    >
                      Price
                    </th>
                    <th
                      scope="col"
                      className="w-1/5 py-3 pr-8 font-normal sm:table-cell"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="w-0 py-3 text-right font-normal"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t">
                  {userBookings?.map((booking) => (
                    <tr
                      key={booking.uid}
                      className="cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300"
                      onClick={() =>
                        handleViewProduct(`/booking/${booking?.uid}`)
                      }
                    >
                      <td className="py-6 pr-8">
                        <div className="flex items-center">
                          <img
                            src={booking?.voucher?.brand?.logo}
                            alt="voucherImage"
                            className="mr-6 h-16 w-16 rounded object-cover object-center"
                          />
                          <div>
                            <div className="font-medium text-gray-900">
                              {booking?.voucher?.brand?.name}
                              <div className="text-xs text-gray-500">
                                {booking?.voucher?.description}
                              </div>
                            </div>
                            <div className="mt-1 sm:hidden">
                              {booking?.voucher?.currency}{" "}
                              {booking?.amount}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="hidden py-6 pr-8 sm:table-cell">
                        {booking?.voucher?.currency} {booking?.amount}
                      </td>
                      {booking?.status === "FAILED" && (
                        <td className="text-rose-600 py-6 pr-8 sm:table-cell">
                          {booking?.status}
                        </td>
                      )}
                      {["PAYMENT_COMPLETE", "PENDING"].includes(
                        booking?.status
                      ) && (
                        <td className="text-yellow-600 py-6 pr-8 sm:table-cell">
                          {booking?.status}
                        </td>
                      )}
                      {booking?.status === "COMPLETE" && (
                        <td className="text-green-600 py-6 pr-8 sm:table-cell">
                          {booking?.status}
                        </td>
                      )}
                      <td className="whitespace-nowrap py-6 text-right font-medium">
                        <button
                          className="text-indigo-600"
                          onClick={() =>
                            handleViewProduct(
                              `/brands/${booking?.voucher?.brand?.uid}`
                            )
                          }
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <ScreenLoaderComponent />
  );
}
