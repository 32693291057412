import FooterComponent from "src/Components/Footer";
import LoginLayout from "src/Layouts/Login";

const LoginPage = () => {
  return (
    <>
      <div className="min-h-full">
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <LoginLayout />
          </div>
        </main>
        <FooterComponent />
      </div>
    </>
  );
};

export default LoginPage;
